import styled from 'styled-components';
import { CopyLink } from '../SongContainer/CopyLink';

export const StyledModalHeader = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const StyledModalCloseButton = styled.div`
  font-size: 2rem;
  font-weight: 500;
  line-height: 1;
  color: #000;
  opacity: 1.0;
  cursor: pointer;
  border: none;
`

export const StyledButton = styled.button`
  font-size: .9rem;
  font-weight: 700;
  border: none;
  border-radius: 3px;
  padding: .3rem 1rem;
  margin-left: .5rem;
  size: 80px;
}
`

export const StyledNewModal = styled.div`
  padding: 4px;
  
  width: 80%; 
  height: 80vh;
  border: 1px solid black;


  z-index: 100;
  background: white;
  background-color: #fefefe;
  position: relative;
  margin: 1.75rem auto;
  border-radius: 3px;
  max-width: 500px;
  padding: 2rem;

  font-size: 1.25rem;

  // not my best work but it's fine
  grid-area: main;

  margin: auto;
`
const SurveyModal = (
  {
    isShowing,
    hide
  }:
    {
      isShowing: boolean,
      hide: () => void
    }
) => {
  if (!isShowing) {
    return null
  }




  return <StyledNewModal >
    <StyledModalHeader>
      <StyledButton data-dismiss="modal" aria-label="Close" onClick={hide}>
        <span aria-hidden="true">&times;</span>
      </StyledButton>
    </StyledModalHeader>
    <h2>
      Hello fellow Swifties!
    </h2>
    <p>
      Hate to bother you this election... but since I have your attention, I wanted to share a cool opportunity with you!
    </p>
    <p>
      If you live in Arizona, Georgia, Michigan - my home state! -  North Carolina, or Pennyslvania <a>and</a> you want to help elect Kamala Harris,
      you can earn up to 200 dollars yapping with your friends and making vote plans with them.
    </p>
    <p>You can also check out some more software that I helped build which is also pretty cool... but maybe not as cool as getting Kamala Harris elected 🤷‍♀️</p>
    <p>Apply here at <a href="https://go.rally.win/j/yRqklJ">turnoutthevote.com</a> </p>
    <CopyLink />
    <p>Feel free to share this with a friend! I'm also happy to <a href="mailto:tess.huelskamp@gmail.com">answer</a> any questions you might have too!</p>

    <h3>Not interested? Already helping another way?</h3>
    <p>
      Thanks okay! <a onClick={(e) => { e.preventDefault(); hide() }}>Closing</a> this modal will hide this message for a while!
    </p>
    <p>Thanks! -Tess </p>
    <StyledModalHeader onClick={hide}>
      <p >
        Click here to exit
      </p>
    </StyledModalHeader>
  </StyledNewModal>
}


export default SurveyModal;